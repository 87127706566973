'use client';

import type { Cookiebot } from '@/types/cookiebot';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

/**
 * This component has 2 roles:
 *
 * - Forces the Cookiebot widget to reload when the path changes
 * - Refreshes the page when the user accepts or declines cookies
 */
export default function CookiebotExtra() {
  const [isLoaded, setIsLoaded] = useState(false);
  const pathname = usePathname();

  // This is a hack to force the Cookiebot widget to reload when the path changes. Hopefully clarity
  // will come from support here:
  // https://support.cookiebot.com/hc/en-us/community/posts/13337105836444-Reinitialise-widget-when-navigating-to-new-page-JavaScript-framework-Router-NextJS
  useEffect(() => {
    if (!isLoaded) return;
    const Cookiebot = window.Cookiebot as Cookiebot;

    if (Cookiebot.widget) {
      Cookiebot.widget.loaded = false;
      Cookiebot.initWidget();
      setIsLoaded(true);
    }
  }, [pathname, isLoaded]);

  // This is used to refresh the page when the user accepts or declines cookies. Although this isn't
  // the cleanest solution, it's what we need for moneypenny to not fire if consent has changed.
  useEffect(() => {
    const handleConsentChange = () => {
      const Cookiebot = window.Cookiebot as Cookiebot;

      if (Cookiebot.changed && Cookiebot.hasResponse) {
        window.location.reload();
      }
    };

    // Add event listeners for Cookiebot consent changes
    window.addEventListener('CookiebotOnConsentReady', handleConsentChange);

    return () => {
      // Cleanup event listeners when component unmounts
      window.removeEventListener('CookiebotOnConsentReady', handleConsentChange);
    };
  }, []);

  return null;
}
